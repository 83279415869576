import "./types";
import { VOCSURVEYS_MSG_PREFIX, VOCSURVEYS_IFRAME_ID } from "./constants";

export const IFrameHelper = {
  getIFrame: (channel): HTMLIFrameElement => {
    return document.getElementById(
      VOCSURVEYS_IFRAME_ID(channel),
    ) as HTMLIFrameElement;
  },

  isIFrame: (): boolean => window.self !== window.top,

  buildMessagePayload: (channel: string, key: string, value: any): string => {
    if (channel === "") {
      console.error(`Channel was empty for message ${key}`);
    }
    return `${VOCSURVEYS_MSG_PREFIX(channel)}:${JSON.stringify({ event: key, ...value })}`;
  },

  sendGlobalMessage: (channel: string, key: string, msg: any = {}): void => {
    if (channel === "") {
      console.error(`Channel was empty for message ${key}`);
    }

    window.parent.postMessage(
      IFrameHelper.buildMessagePayload(channel, key, msg),
      "*",
    );
  },

  sendMessageToIFrame: (channel: string, key: string, value: any): void => {
    if (channel === "") {
      console.error(`Channel was empty for message ${key}`);
    }
    const el = IFrameHelper.getIFrame(channel) as HTMLIFrameElement;
    if (!el) return;

    el.contentWindow.postMessage(
      IFrameHelper.buildMessagePayload(channel, key, value),
      "*",
    );
  },

  isVocSurveyWidgetMessage: (channel: string, data: any): boolean => {
    return (
      typeof data == "string" &&
      data.indexOf(`${VOCSURVEYS_MSG_PREFIX(channel)}:`) == 0
    );
  },

  getMessagePayload: (channel: string, data: any): VocSurveysMessage => {
    const payload = JSON.parse(
      data.replace(`${VOCSURVEYS_MSG_PREFIX(channel)}:`, ""),
    );
    return payload;
  },
};
